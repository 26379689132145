import React from 'react';

import './Content.scss';

const Content = ({ children, fullwidth, textblock, video }) => {
  const classNames = ['content'];

  if (fullwidth) classNames.push('fullwidth');
  if (textblock) classNames.push('textblock');
  if (video) classNames.push('video');

  return (
    <main className={classNames.join(' ')}>
      {children}
    </main>
  )
};

export default Content;
